
import { AllowedAttributes, ProductData, Variant } from '../../../contexts'
import { logger } from '../../../support'

import { IProductsRepository, IProductStockRepository } from '../contracts/repositories'
import { AnyObject } from '@movecloser/front-core'

interface InputProduct {
  sku: string
  quantity: number
}

export type OutputProduct = InputProduct

interface ConsiderableInputProduct extends InputProduct {
  availableQty: number
}

const getBoxExceptionValue = (object: AnyObject): boolean => {
  const boxException = object.attributes[AllowedAttributes.BoxException] as any

  if (typeof boxException === 'string') {
    return boxException === '1'
  }

  if (typeof boxException === 'object' && 'value' in boxException) {
    return boxException.value === '1'
  }

  return false
}

/**
 * Helper method that, based on products requested quantities (input)
 * reduces theirs output quantity considering: Magento availability and expected number of packages to be sold
 * @param isUserB2B
 * @param inputProducts
 * @param productsStockRepository
 * @param productsRepository
 * @param resolvedVariants
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const considerStockAvailabilityAndPackages = async (
  isUserB2B: boolean,
  inputProducts: Array<InputProduct>,
  productsStockRepository: IProductStockRepository,
  productsRepository: IProductsRepository,
  resolvedVariants?: Array<Variant<string>>
): Promise<Array<OutputProduct>> => {
  try {
    const stockProducts = await productsStockRepository.getProductsSellableQuantity(inputProducts.map(p => p.sku))

    let candidateOutputProducts: Array<ConsiderableInputProduct | OutputProduct> = []
    let unavailableStock: Array<ConsiderableInputProduct | OutputProduct> = []

    // Divide input products into available candidates and those which requested quantity exceeds available stock
    for (const p of inputProducts) {
      const found = stockProducts.find((stockItem) => stockItem.sku === p.sku)
      if (!found) {
        break
      }

      if (found.sellableQuantity >= p.quantity) {
        candidateOutputProducts.push({
          ...p,
          availableQty: found.sellableQuantity
        })
      } else {
        unavailableStock.push({
          ...p,
          availableQty: found.sellableQuantity
        })
      }
    }

    if (candidateOutputProducts.length > 0) {
      candidateOutputProducts = await getQuantityConsideredProducts(
        isUserB2B,
        (candidateOutputProducts as Array<ConsiderableInputProduct>).filter(p => p.availableQty > 0) as Array<ConsiderableInputProduct>,
        productsRepository,
        resolvedVariants
      )
    }

    if (unavailableStock.length > 0) {
      unavailableStock = await getQuantityConsideredProducts(
        isUserB2B,
        (unavailableStock as Array<ConsiderableInputProduct>).filter(p => p.availableQty > 0) as Array<ConsiderableInputProduct>,
        productsRepository,
        resolvedVariants
      )
    }

    return candidateOutputProducts
      .concat(unavailableStock)
  } catch (e) {
    logger(e, 'warn')
    return []
  }
}

/**
 * Consider products quantity based on criteria: current stock availability and product `package_amount`
 * @param isUserB2B
 * @param inputProducts
 * @param productsRepository
 * @param resolvedVariants
 */
const getQuantityConsideredProducts = async (
  isUserB2B: boolean,
  inputProducts: Array<ConsiderableInputProduct>,
  productsRepository: IProductsRepository,
  resolvedVariants?: Array<Variant<string>>
): Promise<Array<OutputProduct>> => {
  let registryItems: Array<ProductData> = []

  if (!resolvedVariants || resolvedVariants.length === 0) {
    try {
      registryItems = await productsRepository.loadProductsBySkus(inputProducts.map(p => p.sku))
    } catch (e) {
      logger(e, 'warn')
      return []
    }

    if (registryItems.length === 0) {
      return []
    }
  }

  return inputProducts
    .map(p => {
      let found

      if (resolvedVariants && resolvedVariants.length > 0) {
        found = resolvedVariants.find(variant => variant.sku === p.sku)
      } else {
        found = registryItems.find(item => Object.values(item.variants)[0].sku === p.sku)
      }

      if (!found) {
        return p
      }

      let packageAmount: number

      let boxException
      if (resolvedVariants && resolvedVariants.length > 0) {
        boxException = getBoxExceptionValue(found as Variant<string>)
      } else {
        boxException = getBoxExceptionValue(Object.values((found as ProductData).variants)[0])
      }

      if (!boxException) {
        let packageAmountAttr: string | undefined
        if (resolvedVariants && resolvedVariants.length > 0) {
          packageAmountAttr = (found as Variant<string>).attributes[AllowedAttributes.PackageAmount] as string | undefined
        } else {
          packageAmountAttr = Object.values((found as ProductData).variants)[0].attributes[AllowedAttributes.PackageAmount] as string | undefined
        }
        packageAmount = packageAmountAttr ? parseFloat(packageAmountAttr) : 1
      } else {
        packageAmount = 1
      }

      let quantity = p.quantity

      if (packageAmount) {
        quantity = Math.ceil(quantity / packageAmount) * packageAmount
      }

      if (quantity > p.availableQty) {
        quantity = Math.floor(p.availableQty / packageAmount) * packageAmount
      }

      return {
        sku: p.sku,
        quantity
      }
    })
}
